<template>
    <div class="system-container">
        <el-row>
            <!-- <el-button type="primary" size="small">查询</el-button> -->
        </el-row>
        <el-row>
            <div class="data-title">
                <span class="data-title-text">数据项分类</span>
            </div>
        </el-row>
        <el-row>
            <div class="data-tag">
                <el-tag
                    :key="tag.id"
                    v-for="tag in dynamicTags"
                    closable
                    :disable-transitions="false"
                    @click="handleClick(tag)"
                    @close="handleClose(tag)"
                    :effect="typeCode == tag.code? 'dark':'light'">
                    {{tag.name}}
                </el-tag>
                <el-input
                    class="input-new-tag"
                    v-if="inputVisible"
                    v-model="inputValue"
                    ref="saveTagInput"
                    size="small"
                    @keyup.enter.native="handleInputConfirm"
                    @blur="handleInputConfirm"
                >
                </el-input>
                <el-button v-else class="button-new-tag" size="small" @click="showInput">+ 新增分类</el-button>
            </div>
        </el-row>
        <el-row  v-show="dynamicTags.length > 0">
            <div class="data-title">
                <span class="data-title-text"> <span style="color: #0188e2;font-weight: 600; font-size: 15px;margin-right: 5px;">{{typeName}}</span>数据项明细</span>
            </div>
        </el-row>
        <el-row  v-show="dynamicTags.length > 0">
            <div class="data-tag">
                <el-table :data="detaillist" border fit highlight-current-row
                    style="width: 80%"
                    :row-class-name="tableRowClassName"
                    @row-click="editData">
                    <el-table-column align="center" label="排序" width="60">
                        <template slot-scope="scope">
                        <span>{{scope.$index + 1}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="编码" width="145">
                        <template slot-scope="scope">
                            <span v-if="scope.row.id !=0">{{ scope.row.dictCode }}</span>
                            <el-input v-else v-model="scope.row.dictCode"></el-input>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="中文名称" width="145">
                        <template slot-scope="scope">
                            <span v-if="scope.row.id !=0">{{scope.row.typeName}}</span>
                            <el-input v-else v-model="scope.row.typeName"></el-input>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="英文名称" width="145">
                        <template slot-scope="scope">
                            <span v-if="scope.row.id !=0">{{scope.row.typeEnName}}</span>
                            <el-input v-else v-model="scope.row.typeEnName"></el-input>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="状态" width="145">
                        <template slot-scope="scope">
                            <span>{{scope.row.statusName}}</span>
                            <!-- <el-input v-else v-model="scope.row.statusName"></el-input> -->
                        </template>
                    </el-table-column>
                    <el-table-column align="left" label="操作">
                        <template slot-scope="scope">
                        <el-button
                            size="mini"
                            :disabled="scope.$index===0"
                            @click="moveUp(scope.$index,scope.row)"><i class="el-icon-arrow-up"></i></el-button>
                        <el-button
                            size="mini"
                            :disabled="scope.$index===(detaillist.length-1)"
                            @click="moveDown(scope.$index,scope.row)"><i class="el-icon-arrow-down"></i></el-button>
                        <el-button size="mini" type="danger" @click="removeData(scope.row, scope.$index)" v-show="detaillist.length>1">
                            删除
                        </el-button>
                        <el-button size="mini" type="primary" plain @click="saveData(scope.row)" v-show="scope.row.id ==0">
                            保存
                        </el-button>
                        <el-button size="mini" type="primary" @click="addData(scope.$index)" v-show="scope.$index===(detaillist.length-1)">
                            增加
                        </el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </el-row>
    </div>
</template>

<script>
import api from "@/api/index.js";
import constants from "@/constants/Map.constants.js";
const gap = 100;
export default {
    name: "dataMaintain",
    data(){
        return {
            options: {
                page: 1,
                size: 10,
                parameter: "",
                cusNo: ""
            },
            dynamicTags: [],
            inputVisible: false,
            inputValue: '',
            detaillist: [],
            editDataIndex: -1,
            typeCode: "",
            typeName: "",
            effect: "light"
        }
    },
    created(){
        this.options.cusNo = sessionStorage.cusNo;
        this.searchCusdictionary(0);
    },
    methods: {
        //获取数据项分类数据
        searchCusdictionary(index = 0) {
            api.searchCusdictionary({
                cusNo: sessionStorage.cusNo
            }).then(res => {
                if(res.code == 200) {
                    this.dynamicTags = [];
                    this.dynamicTags = res.data.data;
                    if(this.dynamicTags.length > 0) {
                        this.typeCode = this.dynamicTags[index].code;
                        this.typeName = this.dynamicTags[index].name;
                        this.searchCusdictionaryitem(this.typeCode);
                    }
                }
            })
        },
        //获取数据项明细数据
        searchCusdictionaryitem(typeCode) {
            this.typeCode = typeCode;
            api.searchCusdictionaryitem({
                cusNo: sessionStorage.cusNo,
                typeCode
            }).then(res => {
                if(res.code == 200) {
                    if(res.data.data.length == 0) {
                        this.detaillist = [{
                            dictCode: "",
                            id: 0,
                            remark: "",
                            sortNo: 0,
                            status: 1,
                            statusName: "可用",
                            typeCode: typeCode,
                            typeEnName: "",
                            typeName: "",
                        }];
                    } else {
                        this.detaillist = res.data.data.sort((a, b) => {
                            return a.sortNo - b.sortNo;
                        });
                    }
                }
            })
        },
        //选择分类项，查询分类明细
        handleClick(tag) {
            this.searchCusdictionaryitem(tag.code);
            this.typeName = tag.name;
        },
        //删除数据项分类
        handleClose(tag) {
            api.deleteCusdictionaryById({
                id: tag.id
            }).then(res => {
                if(res.code == 200) {
                    //this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1);
                    if(this.dynamicTags.length == 0) {
                        this.detaillist = [];
                    } else {
                        this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1);
                        this.searchCusdictionary(0);
                    }
                } else {
                    this.$message.error("删除失败");
                }
            })
            
        },
        showInput() {
            this.inputVisible = true;
            this.$nextTick(_ => {
                this.$refs.saveTagInput.$refs.input.focus();
            });
        },
        // 新增数据项分类保存
        handleInputConfirm() {
            let inputValue = this.inputValue;
            if (inputValue) {
                api.insertCusdictionary({
                    creator: sessionStorage.userName,
                    cusNo: sessionStorage.cusNo,
                    name: inputValue,
                    code: inputValue
                }).then(res => {
                    if(res.code == 200) {
                      //  this.dynamicTags.push(inputValue);
                        this.searchCusdictionary(this.dynamicTags.length);
                    } else {
                        this.$message.error("新增数据分类失败");
                    }
                })
            }
            this.inputVisible = false;
            this.inputValue = '';
        },
        //向上移动
        moveUp(index, row) {
            var that = this;
            let type = "moveUp";
            if (index > 0) {
                api.updateCusdictionaryitemSortById({
                    id: row.id,
                    sortNo: row.sortNo,
                    typeCode: this.typeCode,
                    type
                }).then(res => {
                    if(res.code == 200) {
                        let upDate = that.detaillist[index - 1];
                        that.detaillist.splice(index - 1, 1);
                        that.detaillist.splice(index, 0, upDate);
                    } else {
                        this.$message.error("上移位置失败");
                    }
                });
            } else {
                this.$message({
                    type: "warning",
                    message: "已经是第一条，不可上移"
                });
            }
        },
        //向下移动
        moveDown(index, row) {
            var that = this;
            let type = "moveDown";
            if ((index + 1) === that.detaillist.length) {
                this.$message({
                    type: "warning",
                    message: "已经是最后一条，不可下移"
                });
            } else {
                api.updateCusdictionaryitemSortById({
                    id: row.id,
                    sortNo: row.sortNo,
                    typeCode: this.typeCode,
                    type
                }).then(res => {
                    if(res.code == 200) {
                        let downDate = that.detaillist[index + 1];
                that.detaillist.splice(index + 1, 1);
                that.detaillist.splice(index, 0, downDate);
                    } else {
                        this.$message.error("下移位置失败");
                    }
                });
                
            }
        },
        //新增数据项明细行
        addData() {
            this.detaillist.push({
                    dictCode: "",
                    id: 0,
                    remark: "",
                    sortNo: 0,
                    status: 1,
                    statusName: "可用",
                    typeCode: this.typeCode,
                    typeEnName: "",
                    typeName: "",
                });
        },
        //移除
        removeData(item, index) {
            if(this.detaillist.length <= 1) {
                this.detaillist = [{code: ""}];
            } else {
                if(item.id == 0) {
                    this.detaillist.splice(index, 1);
                } else {
                    api.deleteCusdictionaryitemById({
                        id: item.id
                    }).then(res => {
                        if(res.code == 200) {
                            this.detaillist.splice(index, 1);
                        } else {
                            this.$message.error("删除失败");
                        }
                    });
                }
            }
        },
        //保存数据明细
        saveData(item) {
            item.cusNo = sessionStorage.cusNo;
            if(!!item.dictCode && !!item.typeEnName && !!item.typeName) {
                api.insertCusdictionaryitem(item).then(res => {
                    if(res.code == 200) {
                        this.searchCusdictionaryitem(this.typeCode);
                    }
                });
            } else {
                this.$message({
                    type: "warning",
                    message: "保存数据不能为空！"
                });
            }
        },
        tableRowClassName ({row, rowIndex}) {
           //把每一行的索引放进row
           row.index = rowIndex;
           
        },
        editData(row, event, column) {
            console.log(row, event, column);
            this.editDataIndex = row.index;
        }
    }
}
</script>
<style>
.data-title {
    border-left: 4px solid #0188e2;
    margin-top: 30px;
    padding-left: 30px;
}
.data-title-text {
    font-size: 14px;
}
.data-tag {
    margin-top: 30px;
}
.data-tag .el-tag {
    cursor: pointer;
}
.data-tag .el-tag + .el-tag {
    margin-left: 10px;
}
.data-tag .button-new-tag {
    margin-left: 10px;
    height: 32px;
    line-height: 30px;
    padding-top: 0;
    padding-bottom: 0;
}
.data-tag .input-new-tag {
    width: 90px;
    margin-left: 10px;
    vertical-align: bottom;
}

.system-container {
    font-size: 13px;
    padding: 10px 30px;
}
.system-text {
    font-size: 13px;
}
.el-dropdown-link1 {
    cursor: pointer;
    color: #409EFF;
    font-size: 12px;
  }
  .el-icon-arrow-down {
    font-size: 12px;
  }
  .system-container .number {
      float: left;
  }
  .system-container .more {
      float: left;
  }
</style>
